import React from "react";
import '../styles/Features.css';

function Features() {
    return (
        <div>
            <h3 className="mt-4 mb-3 text-center">Дополнительные возможности</h3>
            <div className="row g-2">
                <div className="col-md-8">
                    <div className="row h-100 g-2">
                        <div className="col-6">
                            <div className="h-100">
                                <div className="feature-card bg-white h-100 feature-card-slave">
                                    <h5><i className="color-green bi bi-safe"></i> Без убытков</h5>
                                    <p> Цена никогда не будет ниже минимальной и
                                        выше максимальной, заданной вами.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="h-100">
                                <div className="feature-card bg-white h-100 feature-card-slave">
                                    <h5><i className="color-green bi bi-gear"></i> Управление с предзаказами и остатками</h5>
                                    <p>Все изменения, касающиеся остатков и предзаказов,
                                        синхронизируются с вашим магазином на Kaspi.kz, что позволяет избежать ошибок в учёте товаров.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="h-100">
                                <div className="feature-card bg-white h-100 feature-card-slave">
                                    <h5><i className="color-green bi bi-shop"></i> Поддержка нескольких магазинов</h5>
                                    <p> Один пользователь может управлять несколькими
                                        магазинами.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="h-100">
                                <div className="feature-card bg-white h-100 feature-card-slave">
                                    <h5><i className="bi bi-toggles color-green"></i> Алгоритмы</h5>
                                        <div><strong>Быть первым
                                            —</strong> первая цена - шаг.
                                        </div>
                                        <div><strong>Следовать за первым
                                            —</strong> первая цена + шаг.
                                        </div>
                                        <div><strong>Быть вторым
                                            —</strong> вторая цена - шаг.
                                        </div>
                                        <div>
                                            <strong>Автовыход из ямы минимальной цены</strong>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-md-4">
                    <div className="h-100" style={{'position': 'relative'}}>
                        <div className="h-100 bg-blue-glowing" style={{'padding-bottom': 8}}>
                            <div className="feature-card">
                                <div className='row align-items-center'>
                                    <div className='col-8'>
                                        <h5 className={'mb-3'}> Авторассылка сообщений через&nbsp;
                                            <i className="color-green bi bi-whatsapp"></i><span
                                                className={'color-green'}>WhatsApp</span></h5>
                                        <p><i className={'bi bi-star-fill color-gold'}></i>
                                            <i className={'bi bi-star-fill color-gold'}></i>
                                            <i className={'bi bi-star-fill color-gold'}></i>
                                            <i className={'bi bi-star-fill color-gold'}></i>
                                            <i className={'bi bi-star-fill color-gold'}></i>
                                            <br/>
                                            <br/>
                                            Автоматические сообщения при заказе и после выдачи с просьбой об <b>отзыве</b>. Повышайте рейтинг, улучшайте сервис и укрепляйте доверие клиентов. </p>
                                    </div>
                                    <div className='col-4'>
                                        <img className={'why-card-img animate-dance'} src={'/3d-speaker_10473305.png'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Features;
