import React, { useState, useEffect } from "react";
import "../styles/ExitIntentModal.css";

function ExitIntentModal() {
    const [showModal, setShowModal] = useState(false);
    const promoLink = "https://pricefeed.kz/cabinet/register?code=RQF9J";

    useEffect(() => {
        // Scroll behavior detection
        let lastScrollY = window.scrollY;
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY < lastScrollY - 200) {
                // User scrolled up quickly
                setShowModal(true);
            }
            lastScrollY = currentScrollY;
        };

        // Inactivity detection
        let inactivityTimer;
        const resetInactivityTimer = () => {
            clearTimeout(inactivityTimer);
            inactivityTimer = setTimeout(() => setShowModal(true), 30000); // 30 seconds of inactivity
        };

        // Back button detection
        const handlePopState = () => {
            setShowModal(true);
        };

        // Mouseleave detection
        const handleMouseLeave = (e) => {
            if (e.clientY <= 0) {
                // Trigger only when leaving the top of the viewport
                setShowModal(true);
            }
        };

        // Add event listeners
        window.addEventListener("scroll", handleScroll);
        document.addEventListener("touchmove", resetInactivityTimer);
        document.addEventListener("keydown", resetInactivityTimer);
        document.addEventListener("mousemove", resetInactivityTimer);
        window.addEventListener("popstate", handlePopState);
        document.addEventListener("mouseleave", handleMouseLeave);


        // Set initial inactivity timer
        resetInactivityTimer();

        return () => {
            // Cleanup event listeners
            window.removeEventListener("scroll", handleScroll);
            document.removeEventListener("touchmove", resetInactivityTimer);
            document.removeEventListener("keydown", resetInactivityTimer);
            window.removeEventListener("popstate", handlePopState);
            document.removeEventListener("mouseleave", handleMouseLeave);
        };
    }, []);

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <>
            {showModal && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <button className="modal-close-btn" onClick={handleClose}>
                            <i className={"bi bi-x-lg"}></i>
                        </button>
                        <div className={'p-3'}>
                            <h3>🎁 Не уходите с пустыми руками!</h3>
                            <p>
                                Зарегистрируйтесь прямо сейчас и получите бонус <b>+1000₸</b> на ваш баланс!
                            </p>
                            <a href={promoLink} rel="noopener noreferrer" className="btn btn-primary">
                                Зарегистрироваться и получить бонус
                            </a>
                        </div>
                        </div>
                    </div>
                    )}
                </>
    );
}

export default ExitIntentModal;
